var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "nav-item",
      class: { "sidebar-group-active active": _vm.isActive }
    },
    [
      _c(
        "b-link",
        { staticClass: "nav-link", attrs: { to: { name: _vm.item.route } } },
        [
          _c("feather-icon", { attrs: { size: "14", icon: _vm.item.icon } }),
          _c("span", [_vm._v(_vm._s(_vm.item.title))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }