var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "dropdown nav-item",
      class: {
        "sidebar-group-active active open": _vm.isActive,
        show: _vm.isOpen
      },
      on: {
        mouseenter: function() {
          return _vm.updateGroupOpen(true)
        },
        mouseleave: function() {
          return _vm.updateGroupOpen(false)
        }
      }
    },
    [
      _c(
        "b-link",
        { staticClass: "nav-link dropdown-toggle d-flex align-items-center" },
        [
          _c("feather-icon", { attrs: { size: "14", icon: _vm.item.icon } }),
          _c("span", [_vm._v(_vm._s(_vm.item.header))])
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "dropdown-menu" },
        _vm._l(_vm.item.children, function(child) {
          return _c(_vm.resolveHorizontalNavMenuItemComponent(child), {
            key: child.title,
            tag: "component",
            attrs: { item: child }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }