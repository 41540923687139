var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav navbar-nav", attrs: { id: "main-menu-navigation" } },
    _vm._l(_vm.items, function(item) {
      return _c(_vm.resolveNavComponent(item), {
        key: item.header || item.title,
        tag: "component",
        attrs: { item: item }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }